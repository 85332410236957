import React, { useContext } from "react";
import { StyledSpacer } from "../globalstyle";
import { StyledGridRow } from "../../commons/Grid";
import { DataContext } from "../../contexts/DataContext";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Link from "../Link/Link";
import {
  StyledBusinessFlipCards,
  StyledBusinessFlipCardsBackground,
  StyledBusinessFlipCardsFlexRow,
  StyledBusinessFlipCard,
  StyledBusinessFlipCardInner,
  StyledBusinessFlipCardFront,
  StyledBusinessFlipCardBack,
  StyledBusinessFlipCardBackBackground,
  StyledBusinessFlipCardBackSection,
  StyledBusinessFlipCardTitle,
  StyledBusinessFlipCardDescription,
  StyledBusinessFlipCardIncludedItem,
  StyledBusinessFlipCardButton,
} from "./style";

const BusinessFlipCards = ({
  oasiAziendale, alveareAziendale, regaliSostenibili, image, allImages,
}) => {
  const imageData = allImages.find(e => e.path === image);
  const { i18nOasiLink } = useContext(DataContext);
  const oasiUrl = i18nOasiLink("/owner/preview/business");

  return (
    <StyledBusinessFlipCards>
      <StyledGridRow noMargin>
        <StyledBusinessFlipCardsFlexRow>
          <BusinessFlipCard card={oasiAziendale} allImages={allImages} link={oasiUrl} />
        </StyledBusinessFlipCardsFlexRow>
      </StyledGridRow>

      <StyledGridRow noMargin>
        <StyledBusinessFlipCardsFlexRow>
          <BusinessFlipCard card={alveareAziendale} allImages={allImages} link='/business/solutions' />
          <BusinessFlipCard card={regaliSostenibili} allImages={allImages} link='/business/solutions' />
        </StyledBusinessFlipCardsFlexRow>
      </StyledGridRow>

      <StyledBusinessFlipCardsBackground>
        <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />
      </StyledBusinessFlipCardsBackground>
    </StyledBusinessFlipCards>
  );
};
const BusinessFlipCard = ({ card, allImages, link }) => {
  const { i18nLink } = useContext(DataContext);
  const imageData = allImages.find(e => e.path === card.image);

  return (
    <StyledBusinessFlipCard>
      <StyledBusinessFlipCardInner>
        <StyledBusinessFlipCardFront>
          <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />
          <StyledSpacer size={3} />
          <StyledBusinessFlipCardTitle dangerouslySetInnerHTML={{ __html: card.title }} />
        </StyledBusinessFlipCardFront>
        <StyledBusinessFlipCardBack isBigOnDesktop={card.isBigOnDesktop}>
          <StyledBusinessFlipCardBackBackground isBigOnDesktop={card.isBigOnDesktop}>
            <StyledBusinessFlipCardBackSection isBigOnDesktop={card.isBigOnDesktop}>
              <StyledBusinessFlipCardTitle dangerouslySetInnerHTML={{ __html: card.title }} />
              <StyledBusinessFlipCardDescription dangerouslySetInnerHTML={{ __html: card.description }} isBigOnDesktop={card.isBigOnDesktop} />
            </StyledBusinessFlipCardBackSection>
            <StyledBusinessFlipCardBackSection isBigOnDesktop={card.isBigOnDesktop}>
              {card.included.map((included, index) => (
                <StyledBusinessFlipCardIncludedItem key={index} dangerouslySetInnerHTML={{ __html: included }} isBigOnDesktop={card.isBigOnDesktop} />
              ))}
              <StyledSpacer size={3} />
              <StyledBusinessFlipCardButton>
                <Link to={i18nLink(link)}>
                  <div dangerouslySetInnerHTML={{ __html: card.button }} />
                </Link>
              </StyledBusinessFlipCardButton>
            </StyledBusinessFlipCardBackSection>
          </StyledBusinessFlipCardBackBackground>
        </StyledBusinessFlipCardBack>
      </StyledBusinessFlipCardInner>
    </StyledBusinessFlipCard>
  );
};

export default BusinessFlipCards;
